<template>
  <div>
    <b-row class="match-height">
      <b-col cols="4">
        <ConsultantChart />
      </b-col>
      <b-col cols="2">
        <VerticalCard
          icon="DollarSignIcon"
          statistic="Text"
          statisticTitle="Due Today"
          color="danger"
          due="today"
        />
      </b-col>
      <b-col cols="2">
        <VerticalCard
          icon="DollarSignIcon"
          statistic="Text"
          statisticTitle="Due This Month"
          color="warning"
          due="this_month"
        />
      </b-col>
      <b-col cols="4">
        <CardWithLineChart />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col cols="2">
        <VerticalCardLead
          icon="ListIcon"
          statistic="Text"
          statisticTitle="Partially Closed"
          color="warning"
          status="partially-closed"
        />
      </b-col>

      <b-col cols="2">
        <VerticalCardLead
          icon="ListIcon"
          statistic="Text"
          statisticTitle="Pending Action"
          color="danger"
          status="pending"
        />
      </b-col>

      <b-col cols="2">
        <VerticalCardLead
          icon="ListIcon"
          statistic="Text"
          statisticTitle="Fully Closed"
          color="success"
          status="fully-closed"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BLink, BRow, BCol, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ConsultantChart from "@/components/consultant/ConsultantChart.vue";
import LeadsByStatusChart from "@/components/DashboardWidgets/LeadsByStatusChart.vue";
import CardWithLineChart from "@/components/DashboardWidgets/CardWithLineChart.vue";
import VerticalCard from "@/components/DashboardWidgets/VerticalCard.vue";
import VerticalCardLead from "@/components/DashboardWidgets/VerticalCardLead.vue";

export default {
  data() {
    return {};
  },
  computed: {},
  components: {
    BLink,
    BRow,
    BCol,
    BButton,
    ToastificationContent,
    ConsultantChart,
    LeadsByStatusChart,
    CardWithLineChart,
    VerticalCard,
    VerticalCardLead,
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    getToken() {
      const token = this.$getUserToken();
      console.log(token);
      return token;
    },
  },
  mounted() {},
};
</script>

<style></style>
