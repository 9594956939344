<template>
  <b-card class="card-transaction" id="leads-by-status" no-body>
    <b-card-header>
      <b-card-title>Leads ({{ title }})</b-card-title>

      <b-dropdown
        variant="link"
        no-caret
        class="chart-dropdown"
        toggle-class="p-0"
        right
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="18"
            class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item :to="`/leads?status=${status}`">
          View More
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>

    <b-card-body>
      <div
        v-for="(lead, index) in leads"
        :key="lead._id"
        :class="index !== 0 ? 'mt-1' : ''"
      >
        <router-link :to="`/leads/${lead._id}`" class="text-muted">
          <div class="transaction-item">
            <b-media no-body>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ lead.company }}
                </h6>
                <small class="text-uppercase">{{ lead.city }}</small>
              </b-media-body>
            </b-media>
            <div class="">
              <small>
                {{ $formatDate(lead.createdAt, "DD-MM-YYYY hh:mm a") }}
              </small>
            </div>
          </div>
        </router-link>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BCardHeader,
  BMedia,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BMediaBody,
} from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    BCardHeader,
    BMedia,
    BDropdown,
    BDropdownItem,
    BCardBody,
    BMediaBody,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      leads: [],
    };
  },
  methods: {
    getLeadsByStatus() {
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/dashboard/leads/get-lead-by-activity-status?status=${this.status}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.leads = response.data.leads;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getLeadsByStatus();
  },
};
</script>

<style>
#leads-by-status {
  min-height: 300px;
}
</style>
