<template>
  <b-card class="text-center" v-b-popover.hover.top="'Payments'">
    <b-avatar class="mb-1" :variant="`light-${color}`" size="45">
      <feather-icon size="21" :icon="icon" />
    </b-avatar>
    <div class="truncate">
      <h2 class="mb-25 font-weight-bolder">
        {{ count }}
      </h2>
      <span>{{ statisticTitle }}</span>
    </div>
    <b-button
      size="sm"
      :to="`/payments?due=${due}`"
      variant="flat-primary"
      class="mt-1"
      >View</b-button
    >
  </b-card>
</template>

<script>
import { BCard, BAvatar, BButton, VBPopover } from "bootstrap-vue";
import axios from "axios";

export default {
  components: {
    BCard,
    BAvatar,
    BButton,
  },
  directives: {
    "b-popover": VBPopover,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "primary",
    },
    due: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      count: 0,
    };
  },
  methods: {
    getPaymentsCount() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/dashboard/payments/count-by-due/${this.due}`,
          {
            headers: {
              Authorization: `Bearer ${this.$getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.count = response.data.count;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getPaymentsCount();
  },
};
</script>
