<template>
  <b-card v-if="counts && counts.length" class="earnings-card">
    <b-row>
      <b-col cols="6">
        <b-card-title class="mb-1">
          Consultants
        </b-card-title>
        <div class="font-weight-bolder">Total {{ totalCount }}</div>
        <h5 class="mb-1"></h5>
        <b-card-text class="text-muted font-small-2">
          <span>The consultants are available in </span>
          <span class="font-weight-bolder">{{ counts.length }}</span
          ><span> sectors.</span>
        </b-card-text>
      </b-col>
      <b-col cols="6">
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="consultantChart.chartOptions"
          :series="counts"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BCardTitle, BCardText } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import axios from "axios";

const $earningsStrokeColor2 = "#28c76f66";
const $earningsStrokeColor3 = "#28c76f33";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BCardText,
    VueApexCharts,
  },
  data() {
    return {
      consultantChart: {
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [],
          labels: [],
          stroke: { width: 0 },
          colors: [
            $earningsStrokeColor2,
            $earningsStrokeColor3,
            $themeColors.success,
          ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  // total: {
                  //   show: true,
                  //   offsetY: 15,
                  //   label: "App",
                  //   formatter() {
                  //     return "53%";
                  //   },
                  // },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Consultants",
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
      totalCount: 0,
      counts: [],
    };
  },
  methods: {
    getConsultants() {
      let token = this.$getUserToken();
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/dashboard/consultants/get-consultant-numbers`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          let totalCount = response.data.totalConsultants;
          let sectorSplit = response.data.consultantBySectors;
          let labels = sectorSplit.map((sector) => {
            return sector._id;
          });
          let counts = sectorSplit.map((sector) => {
            return sector.count;
          });

          this.totalCount = totalCount;
          this.consultantChart.chartOptions.labels = labels;
          this.counts = counts;
        })
        .catch((error) => {
          this.isBusy = false;
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.getConsultants();
  },
};
</script>
